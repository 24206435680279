import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cards from "../../components/Cards";
import "../../layout/global";
import {
  Container,
  ContentHeader,
  Section,
  SectionLeft,
  SectionRight,
  SectionScroll,
  Button,
} from "../../styles/GalleryStyle";
import Accordions from "../../components/MenuAccordion";
import { card } from "../../listPersonality";
import { historyCharacter } from "../../data";

import imgL from "../../images/icon-left.png";
import imgR from "../../images/icon-right.png";
import imgStar from "../../images/star.svg";
import InfiniteScroll from "react-infinite-scroller";

import {
  accessories,
  arms,
  defaultBackground,
  personality,
  headsRet,
  headsOct,
  headsTri,
  prints,
  shoes,
  tShisrts,
  trousers,
} from "../../data";

function Gallery() {
  const [filterNft, setFilterNft] = React.useState([]);
  const [cardFilter, setCardFilter] = React.useState([]);
  const [oneCard, setOneCard] = useState(false);

  const [filterPersonality, setFilterPersonality] = React.useState([]);
  const [filterBackground, setFilterBackground] = React.useState([]);
  const [filterHeadsRet, setFilterHeadsRet] = React.useState([]);
  const [filterHeadsOct, setFilterHeadsOct] = React.useState([]);
  const [filterHeadsTri, setFilterHeadsTri] = React.useState([]);
  const [filterArms, setFilterArms] = React.useState([]);
  const [filterTShisrts, setFilterTShisrts] = React.useState([]);
  const [filterPrints, setFilterPrints] = React.useState([]);
  const [filterTrousers, setFilterTrousers] = React.useState([]);
  const [filterShoes, setFilterShoes] = React.useState([]);
  const [filterAccessories, setFilterAccessories] = React.useState([]);

  function clearFilter() {
    setFilterNft();
    setFilterPersonality(personality);
    setFilterBackground(defaultBackground);
    setFilterHeadsRet(headsRet);
    setFilterHeadsOct(headsOct);
    setFilterHeadsTri(headsTri);
    setFilterArms(arms);
    setFilterTShisrts(tShisrts);
    setFilterPrints(prints);
    setFilterTrousers(trousers);
    setFilterShoes(shoes);
    setFilterAccessories(accessories);
  }

  useEffect(() => {
    const list = [];
    const allsFilter =
      filterNft !== undefined && filterNft !== null ? filterNft : {};

    card.map((item) => {
      const keys = Object.keys(allsFilter);
      let hasFilter = true;
      for (let key of keys) {
        if (item[key] !== allsFilter[key]) {
          hasFilter = false;
          break;
        }
      }
      if (hasFilter) {
        list.push({ ...item, series: item.nftcode });
      }
    });
    setHasMore(true);
    setrecords(itemsPerPage);

    setCardFilter(list);
    configureFilterNft(list);
  }, [filterNft]);

  const configureFilterNft = (cards) => {
    const listPersonalityFilter = [];
    const listbackgroudFilter = [];
    const listHeadRetFilter = [];
    const listHeadOctFilter = [];
    const listHeadTriFilter = [];
    const listArmsFilter = [];
    const listTShirtsFilter = [];
    const listFilterPrints = [];
    const listFilterTrousers = [];
    const listFilterShoes = [];
    const listFilterAccessories = [];
    cards.map((item) => {
      checkAddArray(listPersonalityFilter, "personality", item, null);
      checkAddArray(listbackgroudFilter, "background", item, (newObj, item) => {
        newObj.hex = item.backgroundHex;
        const findBack = defaultBackground.find(
          ({ name }) => name === item.background
        );
        if (findBack && findBack.textColor) {
          newObj.textColor = findBack.textColor;
        }
      });
      if (item.heads && item.heads.indexOf("Rectangular") > -1) {
        checkAddArray(listHeadRetFilter, "heads", item, null);
      }
      if (item.heads && item.heads.indexOf("Triangular") > -1) {
        checkAddArray(listHeadTriFilter, "heads", item, null);
      }
      if (item.heads && item.heads.indexOf("Hexagonal") > -1) {
        checkAddArray(listHeadOctFilter, "heads", item, null);
      }
      if (item.arms !== "x") {
        checkAddArray(listArmsFilter, "arms", item, null);
      }
      checkAddArray(listTShirtsFilter, "tshirts", item, (newObj, item) => {
        newObj.hex = item.tshirtHex;
        const findBack = tShisrts.find(({ name }) => name === item.tshirts);
        if (findBack && findBack.textColor) {
          newObj.textColor = findBack.textColor;
        }
      });
      checkAddArray(listFilterPrints, "print", item, null);
      checkAddArray(listFilterTrousers, "trousers", item, (newObj, item) => {
        newObj.hex = item.trousersHex;
        const findBack = trousers.find(({ name }) => name === item.trousers);
        if (findBack && findBack.textColor) {
          newObj.textColor = findBack.textColor;
        }
      });
      checkAddArray(listFilterShoes, "shoes", item, (newObj, item) => {
        newObj.hex = item.shoesHex;
        const findBack = shoes.find(({ name }) => name === item.shoes);
        if (findBack && findBack.textColor) {
          newObj.textColor = findBack.textColor;
        }
      });
      if (item.accessories !== "x") {
        checkAddArray(listFilterAccessories, "accessories", item, null);
      }
    });
    listPersonalityFilter.sort((a, b) => comparaObj(a, b));
    listbackgroudFilter.sort((a, b) => comparaObj(a, b));
    listHeadRetFilter.sort((a, b) => comparaObj(a, b));
    listHeadOctFilter.sort((a, b) => comparaObj(a, b));
    listHeadTriFilter.sort((a, b) => comparaObj(a, b));
    listArmsFilter.sort((a, b) => comparaObj(a, b));
    listTShirtsFilter.sort((a, b) => comparaObj(a, b));
    listFilterPrints.sort((a, b) => comparaObj(a, b));
    listFilterTrousers.sort((a, b) => comparaObj(a, b));
    listFilterShoes.sort((a, b) => comparaObj(a, b));
    listFilterAccessories.sort((a, b) => comparaObj(a, b));

    addSelectAll(listPersonalityFilter);
    addSelectAll(listbackgroudFilter);
    addSelectAll(listHeadRetFilter);
    addSelectAll(listHeadOctFilter);
    addSelectAll(listHeadTriFilter);
    addSelectAll(listArmsFilter);
    addSelectAll(listTShirtsFilter);
    addSelectAll(listFilterPrints);
    addSelectAll(listFilterTrousers);
    addSelectAll(listFilterShoes);
    addSelectAll(listFilterAccessories);

    setFilterPersonality(listPersonalityFilter);
    setFilterBackground(listbackgroudFilter);
    setFilterHeadsRet(listHeadRetFilter);
    setFilterHeadsOct(listHeadOctFilter);
    setFilterHeadsTri(listHeadTriFilter);
    setFilterArms(listArmsFilter);
    setFilterTShisrts(listTShirtsFilter);
    setFilterPrints(listFilterPrints);
    setFilterTrousers(listFilterTrousers);
    setFilterShoes(listFilterShoes);
    setFilterAccessories(listFilterAccessories);
  };

  const addSelectAll = (list) => {
    if (list.length > 0) {
      list.splice(0, 0, {
        name: "Select all",
        hex: "rgba(255, 255, 255, 0)",
        isAll: true,
      });
    }
  };

  const checkAddArray = (list, name, obj, extraCall) => {
    if (list.find((value) => value && value.name === obj[name]) === undefined) {
      const aux = { name: obj[name] };
      if (extraCall) {
        extraCall(aux, obj);
      }
      list.push(aux);
    }
  };

  const comparaObj = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const itemsPerPage = 30;
  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);
  const loadMore = () => {
    if (records >= cardFilter.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 2000);
    }
  };

  const showItems = (data) => {
    const items = [];
    if (!data) {
      return items;
    }

    const total = records > data.length ? data.length : records;
    for (var i = 0; i < total; i++) {
      items.push(
        <Cards
          card={data[i].imageFile}
          name={data[i].personality}
          price={data[i].price}
          series={data[i].series}
          oneCard={oneCard}
        />
      );
    }
    return items;
  };

  return (
    <>
      <Header />
      <Container>
        <ContentHeader>
          <h1>Gallery</h1>
        </ContentHeader>
        <Section>
          <SectionLeft>
            <div className="header">
              <button onClick={() => setOneCard(false)}>
                <img src={imgL} alt="" />
              </button>
              <Button onClick={clearFilter}>reset filters</Button>
              <button onClick={() => setOneCard(true)}>
                <img src={imgR} alt="" />
              </button>
            </div>
            <div className="divisor">
              <div className="div-1">
                {/* <div className="checkbox">
                  <label for="subscribeNews">Select all</label>
                  <input
                    onClick={(e) => checked(e)}
                    type="checkbox"
                    id="subscribeNews"
                    name="subscribe"
                  />
                </div> */}
              </div>
              <div className="div-2"></div>
            </div>
            <section className="section-main">
              <div className="nav">
                <Accordions
                  setFilterNft={setFilterNft}
                  filterNft={filterNft}
                  filterPersonality={filterPersonality}
                  filterBackground={filterBackground}
                  filterHeadsRet={filterHeadsRet}
                  filterHeadsOct={filterHeadsOct}
                  filterHeadsTri={filterHeadsTri}
                  filterArms={filterArms}
                  filterTShisrts={filterTShisrts}
                  filterPrints={filterPrints}
                  filterTrousers={filterTrousers}
                  filterShoes={filterShoes}
                  filterAccessories={filterAccessories}
                />
              </div>

              {
                filterNft?.personality ?
                  historyCharacter.map(
                    (result) =>
                      result.name === filterNft?.personality && (
                        <div className="info">
                          <h2>{result.name}</h2>
                          <div>
                            <p>{result.birth}</p>
                            <img src={imgStar} alt="" />
                            <p>{result.death}</p>
                          </div>
                          <p>{result?.description1}</p>
                          <p>{result?.description2}</p>
                          <p>{result?.description3}</p>
                          <span className="span-1">{result.note1}</span>
                          <div className="border-dotted"></div>
                          <span className="nota-rodape">{result.note2}</span>
                        </div>
                      )
                  )
                  :
                  <div className="no-info">
                    <p className="no-info-text">
                    Choose personality<br/>to see description
                    </p>
                  </div>
              }
            </section>
          </SectionLeft>
          <SectionScroll>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              useWindow={true}
            >
              <SectionRight
                className={oneCard ? "oneCard" : ""}
                oneCard={oneCard}
              >
                {showItems(cardFilter)}
              </SectionRight>
            </InfiniteScroll>
          </SectionScroll>
        </Section>
        <Footer />
      </Container>
    </>
  );
}

export default Gallery;
