import React from "react";

import { Container } from "./style";

import Modal from "react-modal";
import Magnifier from "react-magnifier";

function Cards(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };

  const defaultUrl =
    "https://brighterheads-images.s3.us-east-2.amazonaws.com/personality/";

  return (
    <Container>
      <img
        onClick={openModal}
        className={`img-card-${props.oneCard}`}
        src={/*props.card*/ defaultUrl + props.card}
      />
      <div className={props.oneCard ? "dadosOne" : "dados"}>
        <div>
          <h4>{props.name}</h4>
          <h5>{props.series}</h5>
          {/* <span>
            <img src={priceImg} />
            Price: {props.price}
          </span> */}
        </div>
        <button>Free to mint</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Magnifier
          zoomFactor={2}
          mgHeight={250}
          mgWidth={250}
          src={defaultUrl + props.card}
          width={400}
        />
        {/* <img
          className={`img-card-${props.oneCard}`}
          src={defaultUrl + props.card}
        /> */}
      </Modal>
    </Container>
  );
}

export default Cards;
