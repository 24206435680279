import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import imgRet from "../../images/rectangular.svg";
import imgOct from "../../images/octagonal.svg";
import imgTri from "../../images/triangular.svg";

import "../../layout/custom.css";

const nextPersonalitySeries = [
  'Ada Lovelace',
  'Albert Einstein',
  'Alexander Graham Bell',
  'Ella Fitzgerald',
  'Johannes Gutenberg',
  'John Locke',
  'Louis Pasteur',
  'Michelangelo',
  'Nicolau Copérnico',
  'Nina Simone',
  'Plato of Athens',
  'Santos Dumont',  
  'Socrates',
  'Vasco da Gama',
  'Voltaire'
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid #000000`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "2.5rem",
          color: "#000000",
          transform: "rotate(90deg)",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#e8fbff",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-18 0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function Accordions(props) {
  const [persona, setPersona] = useState();
  const [backgro, setBackgro] = useState();
  const [head, setHead] = useState();
  const [arm, setArm] = useState();
  const [tshirt, setTshirt] = useState();
  const [prin, setPrin] = useState();
  const [trouse, setTrouse] = useState();
  const [shoe, setShoe] = useState();
  const [acessori, setAcessori] = useState();
  const [expanded, setExpanded] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setPersona();
    setBackgro();
    setHead();
    setArm();
    setTshirt();
    setPrin();
    setTrouse();
    setShoe();
    setAcessori();
  }, [props.filterNft === undefined]);

  const selectAll = "Select all";

  return (
    <div className="accordion" 
    // style={{ width: 350 }}
    >
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography className="font">
            {persona ? persona : "Personality"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal text">
            {props.filterPersonality.map((result) => (
              <p
                id="personality"
                onClick={(e) => {
                  if (result.name === selectAll) {
                    if (props.filterNft && props.filterNft[e.target.id]) {
                      delete props.filterNft[e.target.id];
                      props.setFilterNft({
                        ...props.filterNft,
                      });
                      setPersona();
                    }
                    return;
                  }
                  props.setFilterNft({
                    ...props.filterNft,
                    [e.target.id]: e.target.innerHTML,
                  });
                  setPersona(e.target.innerHTML);
                }}
              >
                {result.name}
              </p>
            ))}
            <div className="vanish-text">
              <h2>Next series</h2>
              {nextPersonalitySeries.map(item => (
                <p className="vanish-text">{item}</p>
              ))}
              <h2>Others to come soon</h2>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion
        className="accordion-text"
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography className="font">{arm ? arm : "Arms"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal text">
            {props.filterArms &&
              props.filterArms.map((result) => (
                <p
                  id="arms"
                  onClick={(e) => {
                    if(result.name === selectAll){
                      if(props.filterNft && props.filterNft[e.target.id]){
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setArm();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: e.target.innerHTML,
                    });
                    setArm(e.target.innerHTML);
                  }}
                >
                  {result.name}
                </p>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography className="font">
            {tshirt ? tshirt : "T-shirt"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal overflow accordion-color">
            {props.filterTShisrts &&
              props.filterTShisrts.map((result) => (
                <div
                  id="tshirts"
                  onClick={(e) => {
                    if (result.name === selectAll) {
                      if (props.filterNft && props.filterNft[e.target.id]) {
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setTshirt();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: result.name,
                    });
                    setTshirt(result.name);
                  }}
                  style={{ backgroundColor: `${result.hex}` }}
                >
                  <p
                    id="tshirts"
                    style={{
                      backgroundColor: `${result.hex}`,
                      color: `${
                        result.textColor ? result.textColor : "#000000"
                      }`,
                    }}
                  >
                    {result.name}
                  </p>
                </div>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography className="font">{prin ? prin : "Print"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal text">
            {props.filterPrints &&
              props.filterPrints.map((result) => (
                <p
                  id="print"
                  onClick={(e) => {
                    if (result.name === selectAll) {
                      if (props.filterNft && props.filterNft[e.target.id]) {
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setPrin();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: e.target.innerHTML,
                    });
                    setPrin(e.target.innerHTML);
                  }}
                >
                  {result.name}
                </p>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography className="font">
            {trouse ? trouse : "Trousers"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal overflow">
            {props.filterTrousers &&
              props.filterTrousers.map((result) => (
                <div
                  id="trousers"
                  onClick={(e) => {
                    if (result.name === selectAll) {
                      if (props.filterNft && props.filterNft[e.target.id]) {
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setTrouse();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: result.name,
                    });
                    setTrouse(result.name);
                  }}
                  style={{ backgroundColor: `${result.hex}` }}
                >
                  <p
                    id="trousers"
                    style={{
                      backgroundColor: `${result.hex}`,
                      color: `${
                        result.textColor ? result.textColor : "#000000"
                      }`,
                    }}
                  >
                    {result.name}
                  </p>
                </div>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography className="font">{shoe ? shoe : "Shoes"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal overflow">
            {props.filterShoes &&
              props.filterShoes.map((result) => (
                <div
                  id="shoes"
                  onClick={(e) => {
                    if (result.name === selectAll) {
                      if (props.filterNft && props.filterNft[e.target.id]) {
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setShoe();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: result.name,
                    });
                    setShoe(result.name);
                  }}
                  style={{ backgroundColor: `${result.hex}` }}
                >
                  <p
                    id="shoes"
                    style={{
                      backgroundColor: `${result.hex}`,
                      color: `${
                        result.textColor ? result.textColor : "#000000"
                      }`,
                    }}
                  >
                    {result.name}
                  </p>
                </div>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography className="font">
            {acessori ? acessori : "Accessories"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal text">
            {props.filterAccessories &&
              props.filterAccessories.map((result) => (
                <p
                  id="accessories"
                  onClick={(e) => {
                    if (result.name === selectAll) {
                      if (props.filterNft && props.filterNft[e.target.id]) {
                        delete props.filterNft[e.target.id];
                        props.setFilterNft({
                          ...props.filterNft,
                        });
                        setAcessori();
                      }
                      return;
                    }
                    props.setFilterNft({
                      ...props.filterNft,
                      [e.target.id]: e.target.innerHTML,
                    });
                    setAcessori(e.target.innerHTML);
                  }}
                >
                  {result.name}
                </p>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography className="font">{head ? head : "Head"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal">
            <div className="div-pai">
              <div className="div-separadora">
                <div>
                  {props.filterHeadsRet &&
                    props.filterHeadsRet.length > 1 &&
                    props.filterHeadsRet.map((result) => (
                      <div
                        id="headsRet"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (result.name === selectAll) {
                            if (props.filterNft && props.filterNft["heads"]) {
                              delete props.filterNft["heads"];
                              props.setFilterNft({
                                ...props.filterNft,
                              });
                              setHead();
                            }
                            return;
                          }
                          props.setFilterNft({
                            ...props.filterNft,
                            ["heads"]: e.target.innerHTML,
                          });
                          setHead(e.target.innerHTML);
                        }}
                      >
                        <p id="headsRet">{result.name}</p>
                      </div>
                    ))}
                </div>
                <img src={imgRet} />
                {false &&
                  props.filterHeadsRet &&
                  props.filterHeadsRet.length > 1 && <img src={imgRet} />}
              </div>
              <div className="div-separadora">
                <div>
                  {props.filterHeadsOct &&
                    props.filterHeadsOct.length > 1 &&
                    props.filterHeadsOct.map((result) => (
                      <div
                        id="headsOct"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (result.name === selectAll) {
                            if (props.filterNft && props.filterNft["heads"]) {
                              delete props.filterNft["heads"];
                              props.setFilterNft({
                                ...props.filterNft,
                              });
                              setHead();
                            }
                            return;
                          }
                          props.setFilterNft({
                            ...props.filterNft,
                            ["heads"]: e.target.innerHTML,
                          });
                          setHead(e.target.innerHTML);
                        }}
                      >
                        <p id="headsOct">{result.name}</p>
                      </div>
                    ))}
                </div>
                <img src={imgOct} />
                {false &&
                  props.filterHeadsOct &&
                  props.filterHeadsOct.length > 1 && <img src={imgOct} />}
              </div>
              <div className="div-separadora">
                <div>
                  {props.filterHeadsTri &&
                    props.filterHeadsTri.length > 1 &&
                    props.filterHeadsTri.map((result) => (
                      <div
                        id="headsTri"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (result.name === selectAll) {
                            if (props.filterNft && props.filterNft["heads"]) {
                              delete props.filterNft["heads"];
                              props.setFilterNft({
                                ...props.filterNft,
                              });
                              setHead();
                            }
                            return;
                          }
                          props.setFilterNft({
                            ...props.filterNft,
                            ["heads"]: e.target.innerHTML,
                          });
                          setHead(e.target.innerHTML);
                        }}
                      >
                        <p id="headsTri">{result.name}</p>
                      </div>
                    ))}
                </div>
                <img src={imgTri} />
                {false &&
                  props.filterHeadsTri &&
                  props.filterHeadsTri.length > 1 && <img src={imgTri} />}
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="accordion-text"
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className="font background">
            {backgro ? backgro : "Background"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font-internal overflow accordion-color">
            {props.filterBackground &&
              props.filterBackground.map((result) =>
                result.name === backgro ? (
                  <div style={{ backgroundColor: `${result.hex}` }}>
                    <p
                      style={{ backgroundColor: `${result.hex}` }}
                      id="backgroundp"
                    >
                      {result.name}
                    </p>
                  </div>
                ) : (
                  <div
                    id="background"
                    onClick={(e) => {
                      if (result.name === selectAll) {
                        if (props.filterNft && props.filterNft[e.target.id]) {
                          delete props.filterNft[e.target.id];
                          props.setFilterNft({
                            ...props.filterNft,
                          });
                          setBackgro();
                        }
                        return;
                      }
                      props.setFilterNft({
                        ...props.filterNft,
                        [e.target.id]: result.name,
                      });
                      setBackgro(result.name);
                    }}
                    style={{ backgroundColor: `${result.hex}` }}
                  >
                    <p
                      style={{
                        backgroundColor: `${result.hex}`,
                        color: `${
                          result.textColor ? result.textColor : "#000000"
                        }`,
                      }}
                      id="background"
                    >
                      {result.name}
                    </p>
                  </div>
                )
              )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Accordions;
