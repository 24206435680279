import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 500px) {
    align-items: center;
  }

  .img-card-false {
    width: 96%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
  }

  .img-card-true {
    width: 54%;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;

    @media screen and (max-width: 500px) {
      width: 89%;
    }
  }

  .dadosOne {
    width: 54%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: #e8fbff;
    margin: 0 auto 20px auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media screen and (max-width: 500px) {
      width: 89%;
    }

    > div {
      flex: 1;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: auto;

      > h4,
      h5 {
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 700;
        font-size: 14px;
        margin: -3px;
        padding: 0 0 0 5px;
      }

      > span {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 5px;

        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 14px;

        > img {
          margin-right: 5px;
        }
      }
    }

    > button {
      width: 100px;
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 12px;
      border-radius: 10px;
      border: none;
      background-color: #6052ff;
      color: #ffffff;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      text-align: center;
      align-self: center;
      margin: 20px 10px 20px 0;
      cursor: pointer;
    }
  }

  .dados {
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: #e8fbff;
    margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    > div {
      flex: 1;
      width: 97%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > h4,
      h5 {
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 700;
        font-size: 14px;
        margin: -3px;
        padding: 0 0 0 5px;
        margin-left: 10px;

        @media screen and (max-width: 500px) {
          font-size: 11px;
        }
      }

      > span {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 14px;
        margin-left: 10px;

        @media screen and (max-width: 500px) {
          font-size: 11px;
        }

        h5 {
          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
        }

        > img {
          margin-right: 5px;

          @media screen and (max-width: 500px) {
            width: 9px;
          }
        }
      }
    }

    > button {
      width: 100px;
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 12px;
      border-radius: 10px;
      border: none;
      background-color: #6052ff;
      color: #ffffff;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      text-align: center;
      align-self: center;
      margin: 20px;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        width: 90px;
        font-size: 10px;
      }
    }
  }
`;
