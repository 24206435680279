import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  z-index: 8;
  margin: auto;

  // ajuste no footer
  & .eTjCcb {
    height: 290px;
  }
`;

export const ContentHeader = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;

  > h1 {
    font-family: "Gloria Hallelujah", cursive;
    font-weight: 400;
    font-size: 40px;
    margin-right: 60px;

    @media screen and (max-width: 900px) {
      font-size: 30px;
      margin: 160px 1em 0 0;      
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: -116px;    
    justify-content: end;
  }
`;

export const Section = styled.section`
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 auto 10px auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .oneCard {
    grid-template-columns: 1fr !important;
  }
`;

export const SectionLeft = styled.section`
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #e8fbff;
  margin-left: 50px;
  /* margin-top: -500px; */

  @media screen and (max-width: 900px) {
    margin: 70px auto 10px auto;
    width: 95vw;
  }

  .header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    > button {
      background: none;
      border: none;
      cursor: pointer;
      > img {
        width: 30px;
      }
    }
  }

  .checkbox {
    width: 100%;
    height: 100%;
    align-self: flex-start;
    margin-bottom: -27px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: "Gloria Hallelujah", cursive;

    > label {
      margin: 5px 5px -40px 28px;
    }

    > input {
      margin: 5px 5px -40px 5px;
    }
  }

  .divisor {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
  }

  .div-1 {
    flex: 1;
    height: 100%;
  }

  .div-2 {
    flex: 1;
    height: 100%;
    border-left: 1px solid #000;
  }

  .section-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #e8fbff;

    .nav, .accordion {      
      @media screen and (max-width: 900px){
        display: inline-block;
        width: 100%;
      }

      .accordion-text {
        @media screen and (max-width: 900px){
          width: 100%;
          margin: 0;
        }
      }

    }
  }

  .info, 
  .no-info {
    width: 300px;
    min-height: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 100px 0;
    background: #c8faf1;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    > h2 {
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 400;
      font-size: 20px;
      text-align: center;
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: -10px;

      > p {
        flex: 1;
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        padding: 0 .5em;
      }
    }

    > p {
      width: 260px;
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 14px;
      text-align: justify;
      margin: 6px;
    }

    .span-1 {
      margin-top: 0px;
      font-size: 14px;
      position: relative;
      margin-top: 15px;
    }

    .border-dotted {
      width: 150px;
      margin-top: 60px;
      border: 2px dotted #000;
    }

    > span {
      width: 270px;
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      font-size: 10px;
      text-align: center;
      margin: 30px 10px 10px 10px;
      line-height: 15px;
    }

    @media screen and (max-width: 900px) {
      /* display: none; */
      margin: 30px 0 30px 0;
    }
  }

  .no-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      min-height: 0;

      & p {
        display: block;
        font-family: "Gloria Hallelujah", cursive;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.5;
        text-align: center;
      }
  }
`;

export const Button = styled.button`
  width: 150px;
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  border: none;
  background-color: #336699 !important;
  color: #ffffff;
  padding: 2px 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: center;
  align-self: flex-end;
  margin-bottom: 4px;
  cursor: pointer;
`;

export const SectionScroll = styled.section`
  flex: 1;
  width: 100%;
  padding-left: 30px;
  padding-right: 10px;

  div {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const SectionRight = styled.section`
  flex: 1;
  max-width: 940px;
  width: 100%;
  height: 1576px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly !important;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* margin-left: 50px; */
  /* padding-left: 40px; */
  /* position: absolute;
  top: 407px;
  right: 260px; */

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #c8faf1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #336699;
    border-radius: 5px;
  }

  @media screen and (max-width: 2560px) {
    right: 550px;
  }

  @media screen and (max-width: 1920px) {
    right: 220px;
  }

  @media screen and (max-width: 1720px) {
    right: 140px;
  }

  @media screen and (max-width: 1520px) {
    right: 40px;
  }

  @media screen and (max-width: 1440px) {
    right: 0px;
  }

  @media screen and (max-width: 1366px) {
    /*width: 82%;*/
  }

  @media screen and (max-width: 1280px) {
    max-width: 820px;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    max-height: 600px;
    height: 100%;
    overflow: auto;
  }
`;
